:root {
  --modal-z-index: 10;
}

.ReactModalPortal {
  position: relative;
  z-index: var(--modal-z-index);
}

/* To make drag and drop items visible when dragging within a modal */
body > li {
  position: relative;
  z-index: var(--modal-z-index);
}